import { Card, Grid, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import { sortBy } from 'lodash'
import FMDescription from './FMOutstandingLoadDescription'
import TableDataCell from '../common/TableDataCell'
import DashboardOverview from '../common/DashboardOverview'

const FMOutstandingLoadDashboard = ({
  loadSummary,
  subheader,
  formatStateFilters = (_cell) => {
    return {}
  },
}) => {
  const summaryLength = loadSummary?.dashboard_config.summary_columns
  const xs = Math.floor(12 / (summaryLength + 1))

  const mapLoadSummary = (loadSummary) => {
    const dashboardSummaries = loadSummary?.dashboard_summaries
    const scacs = sortBy(
      [
        ...new Set(dashboardSummaries?.map((details) => details.scac)?.sort()), // Set ensures these scac values are unique
      ].filter((value) => value !== 'All'),
    )
    return scacs.map((scac) => {
      const allSummariesForScac = dashboardSummaries
        .filter((summary) => summary.scac === scac)
        .flatMap((summary) => {
          return summary.summary_cells
        })
      const cellDisplaySequences = [
        ...new Set(
          allSummariesForScac.flatMap((summary) => {
            return summary.display_sequence
          }),
        ),
      ].sort()
      return {
        scac: scac,
        cells: cellDisplaySequences.map((displaySequence) => {
          const summariesForCell = allSummariesForScac.filter(
            (summary) => summary.display_sequence === displaySequence,
          )
          const firstSummaryForCell = allSummariesForScac.find(
            (summary) => summary.display_sequence === displaySequence,
          )
          const allFilters = summariesForCell.flatMap(
            (summary) => summary.filters,
          )

          const filterNames = [
            ...new Set(allFilters.map((filter) => filter.filter_name)),
          ]

          const mappedFilters = filterNames.map((filterName) => {
            return {
              filter_name: filterName,
              filter_values: [
                ...new Set(
                  allFilters
                    .filter((filter) => filter.filter_name === filterName)
                    .flatMap((filter) => filter.filter_values),
                ),
              ],
            }
          })
          return {
            display_sequence: displaySequence,
            display_name: firstSummaryForCell?.display_name,
            count: summariesForCell.reduce((acc, summary) => {
              acc += summary.load_count
              return acc
            }, 0),
            filters: mappedFilters,
          }
        }),
      }
    })
  }

  return (
    <Card className="hc-pa-expanded">
      <Grid.Container direction="column">
        <Grid.Item>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={3}>
                Domestic First Mile Outstanding Loads Dashboard
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <FMDescription />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>{subheader}</Grid.Item>
        <DashboardOverview
          loadSummary={loadSummary}
          formatStateFilters={formatStateFilters}
        />
        {mapLoadSummary(loadSummary).length > 0 && (
          <>
            <Grid.Item>
              <Heading size={4}>
                <strong>Summaries</strong>
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header xs={xs}>SCAC</Table.Header>
                    {mapLoadSummary(loadSummary)[0]?.cells?.map((cell) => (
                      <Table.Header xs={xs} key={`${cell.display_name}`}>
                        {cell.display_name}
                      </Table.Header>
                    ))}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {mapLoadSummary(loadSummary)?.map((summary, index) => {
                    return (
                      <Table.Row data-testid={`row${index}-${summary?.scac}`}>
                        <Table.Data xs={xs}>
                          {<strong>{summary.scac}</strong>}
                        </Table.Data>
                        {summary?.cells?.map((cell) => (
                          <TableDataCell
                            filters={formatStateFilters(cell)}
                            displayText={<strong>{cell.count}</strong>}
                            isDefectCell={true}
                            success={cell.count === 0}
                            xs={xs}
                          />
                        ))}
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Card>
  )
}

export default FMOutstandingLoadDashboard
