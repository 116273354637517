import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'

import {
  Grid,
  Layout,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'

import { ProgressOverlay, SavedSearchMenu } from '@dlm/common'
import apiConfig from '../../config/apiConfig'
import '../../stylesheets/customStyles.scss'

import dashboardService from './services/dashboardService'
import DashboardSearchFilterDrawer from './components/common/DashboardSearchFilterDrawer'
import { chain, clone, find, isEmpty, isString, omit, pull } from 'lodash'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import {
  EnterpriseIcon,
  FilterIcon,
  CaretDownIcon,
  HeartFilledIcon,
  HeartIcon,
} from '@enterprise-ui/icons'
import savedSearchService from '../../common/services/savedSearchService'
import SearchFilterChips from '../../common/components/SearchFilterChips'
import {
  DEFAULT_DASHBOARD_SEARCH_FILTERS,
  DEFAULT_MM_DASHBOARD_SEARCH_FILTERS,
} from './constants/dashboardSearchFilterConstants'
import { FIRST_MILE, MIDDLE_MILE } from './constants/dashboardConstants'
import MMDashboard from './components/MMDashboard/MMDashboard'
import userPreferenceService from '../../common/services/userPreferenceService'
import FMOutstandingLoadDashboard from './components/FMOustandingLoadDashboard/FMOutstandingLoadDashboard'

const Dashboard = () => {
  const auth = useAuth()
  const { session } = auth
  const accessToken = session?.accessToken

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()
  const [fmLoadSummary, setFMLoadSummary] = useState(null)
  const [mmLoadSummary, setMMLoadSummary] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [savedSearchData, setSavedSearchData] = useState('')
  const [dashboardView, setDashboardView] = useState('')
  const [summarySearchFilters, setSummarySearchFilters] = useState({
    selected_filters: [],
    ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
  })
  const [defaultDashboard, setDefaultDashboard] = useState(null)

  const dashboardOptions = [
    {
      label: 'First Mile Dashboard',
      value: FIRST_MILE,
    },
    {
      label: 'Middle Mile Dashboard',
      value: MIDDLE_MILE,
    },
  ]

  const formatStateFilters = (cell) => {
    let cellFilters = {}

    cell.filters?.forEach((filter) => {
      const filterName = filter?.filter_name
      const filterValues = filter?.filter_values

      cellFilters[filterName] = filterValues
    })

    return {
      ...summarySearchFilters,
      ...cellFilters,
    }
  }

  const setDefaultFirstMileSearchFilters = () => {
    setSummarySearchFilters({
      selected_filters: [],
      ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
    })
  }

  const setDefaultMiddleMileSearchFilters = () => {
    setSummarySearchFilters(DEFAULT_MM_DASHBOARD_SEARCH_FILTERS())
  }

  const onSearchFilterChange = useCallback((newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(DEFAULT_DASHBOARD_SEARCH_FILTERS))
      .omitBy(isEmpty)
      .keys()
      .value()

    // Need to re-instate pagination and sort properties as filter drawer strips them
    setSummarySearchFilters({
      ...newFilters,
      selected_filters: updatedSelectedFilters,
    })
  }, [])

  const onSavedSearchLoad = useCallback(
    (newFilters) => {
      if (dashboardView === MIDDLE_MILE) {
        onSearchFilterChange({
          ...newFilters,
          arrival_start_time: summarySearchFilters.arrival_start_time,
          arrival_end_time: summarySearchFilters.arrival_end_time,
        })
      } else onSearchFilterChange(newFilters)
    },
    [dashboardView, onSearchFilterChange, summarySearchFilters],
  )

  const savedSearchRef = useRef(null)
  const getSavedSearches = useCallback(() => {
    setSavedSearchData([])
    if (!dashboardView) return
    let savedSearchType
    switch (dashboardView) {
      case FIRST_MILE:
        savedSearchType = 'FM_DASHBOARD_SAVED_SEARCH'
        break
      case MIDDLE_MILE:
        savedSearchType = 'MM_DASHBOARD_SAVED_SEARCH'
        break
      default:
        break
    }
    savedSearchService
      .getSavedSearches(savedSearchType)
      .then((mappedResponse) => {
        savedSearchRef.current = null
        setSavedSearchData(mappedResponse)
      })
  }, [dashboardView])

  const mapToSavedSearchFilters = (filters) => {
    const mappedFilters = ['origin_ids', 'destination_ids']
    mappedFilters.forEach((filter) => {
      filters[filter] = filters[filter]?.flatMap((filterValue) => {
        return {
          value: filterValue,
          label: filterValue,
          id: filterValue,
        }
      })
    })
    if (dashboardView === MIDDLE_MILE) {
      return omit(filters, ['arrival_start_time', 'arrival_end_time'])
    }
    return filters
  }

  const onSaveSearch = (newFilters) => {
    savedSearchService
      .saveSearch(
        mapToSavedSearchFilters(newFilters),
        dashboardView === FIRST_MILE
          ? 'FM_DASHBOARD_SAVED_SEARCH'
          : 'MM_DASHBOARD_SAVED_SEARCH',
      )
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully saved search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving search',
        })
      })
  }

  const deleteSavedSearch = (criteriaId) => {
    savedSearchService
      .deleteSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully deleted search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error deleting search',
        })
      })
  }

  const markDefault = (criteriaId) => {
    savedSearchService
      .setDefaultSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully marked default',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error marking default',
        })
      })
  }

  const getLoadSummary = useCallback(() => {
    if (savedSearchRef.current) {
      setInProgress(true)
      switch (dashboardView) {
        case FIRST_MILE:
          dashboardService
            .getFMLoadSummary(omit(summarySearchFilters, 'selected_filters'))
            .then((resp) => {
              setFMLoadSummary(resp)
            })
            .catch((e) => {
              makeToast({
                type: 'error',
                heading: 'Server Error',
                message: `Error fetching dashboard data: ${e.response?.data?.error_message || 'Unknown Error'}`,
              })
            })
            .finally(() => {
              // TODO - Remove timeout after https://jira.target.com/browse/EUI-1740 is implemented
              setTimeout(() => setInProgress(false), 500)
            })
          break
        case MIDDLE_MILE:
          dashboardService
            .getMMLoadSummary(omit(summarySearchFilters, 'selected_filters'))
            .then((resp) => {
              setMMLoadSummary(resp)
            })
            .catch((e) => {
              makeToast({
                type: 'error',
                heading: 'Server Error',
                message: `Error fetching dashboard data: ${e.response?.data?.error_message || 'Unknown Error'}`,
              })
            })
            .finally(() => {
              setTimeout(() => setInProgress(false), 500)
            })
          break
        default:
          break
      }
    }
  }, [summarySearchFilters, makeToast, dashboardView])

  const onDashboardSelected = useCallback((dashboard) => {
    if (dashboard === '') setDashboardView(FIRST_MILE)
    if (dashboard === FIRST_MILE) {
      setDashboardView(FIRST_MILE)
      setDefaultFirstMileSearchFilters()
    }
    if (dashboard === MIDDLE_MILE) {
      setDashboardView(MIDDLE_MILE)
      setDefaultMiddleMileSearchFilters()
    }
  }, [])

  const getDashboardUserConfigs = useCallback(() => {
    userPreferenceService
      .getUserPreferences()
      .then(({ data }) => {
        const defaultDashboard =
          data.config_details?.applications?.load_tracking?.favorite_dashboards
            ?.load_tracking_dashboard
        setDefaultDashboard(defaultDashboard)
        onDashboardSelected(defaultDashboard ?? '')
      })
      .catch((error) => {
        if (error?.response?.status !== 404) {
          console.error(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error loading user preferences',
          })
        }
        onDashboardSelected('')
      })
  }, [onDashboardSelected, makeToast])

  useEffect(() => {
    // Fetch saved searches
    getSavedSearches()
  }, [getSavedSearches])

  useEffect(() => {
    getLoadSummary()
  }, [getLoadSummary])

  useEffect(() => {
    getDashboardUserConfigs()
  }, [getDashboardUserConfigs, makeToast])

  useEffect(() => {
    if (!savedSearchRef.current && !isEmpty(savedSearchData)) {
      // Saved searches loaded. We can now fetch default saved search or default search criteria

      const defaultSavedSearch =
        !isString(savedSearchData) &&
        find(savedSearchData, { default_search: true })

      if (defaultSavedSearch) {
        if (dashboardView === FIRST_MILE) {
          onSearchFilterChange(defaultSavedSearch.filters)
        } else {
          onSearchFilterChange({
            ...defaultSavedSearch.filters,
            arrival_start_time: summarySearchFilters.arrival_start_time,
            arrival_end_time: summarySearchFilters.arrival_end_time,
          })
        }
      } else {
        setSummarySearchFilters(clone(summarySearchFilters))
      }
    }

    if (!isEmpty(savedSearchData)) {
      savedSearchRef.current = savedSearchData
    }
  }, [
    summarySearchFilters,
    savedSearchData,
    onSearchFilterChange,
    dashboardView,
  ])

  const onSearchFilterDelete = (filter) => {
    setSummarySearchFilters({
      ...summarySearchFilters,
      selected_filters: pull(summarySearchFilters.selected_filters, filter),
      [filter]: DEFAULT_DASHBOARD_SEARCH_FILTERS[filter],
    })
  }

  const onSearchFilterReset = () => {
    if (dashboardView === MIDDLE_MILE) {
      setSummarySearchFilters({
        selected_filters: [],
        ...DEFAULT_MM_DASHBOARD_SEARCH_FILTERS(),
      })
    } else {
      setSummarySearchFilters({
        selected_filters: [],
        ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
      })
    }
  }

  const onDashboardDefault = (dashboard) => {
    if (dashboard === defaultDashboard) {
      dashboard = ''
    }
    userPreferenceService
      .saveUserDashboardPreferences(dashboard)
      .then(() => {
        if (dashboard) {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: 'Successfully marked dashboard as default',
          })
          onDashboardSelected(dashboard)
        } else {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: 'Successfully removed favorite dashboard',
          })
        }
        setDefaultDashboard(dashboard)
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error marking dashboard as default',
        })
      })
  }

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <DashboardSearchFilterDrawer
        isVisible={filtersVisible}
        onRequestClose={() => {
          setFiltersVisible(false)
        }}
        onChange={onSearchFilterChange}
        onSaveSearch={onSaveSearch}
        savedSearches={savedSearchData}
        givenValues={summarySearchFilters}
        defaultValues={DEFAULT_DASHBOARD_SEARCH_FILTERS}
      />
      <Layout.Body data-testid="dashboard" includeRail>
        <Grid.Container justify="space-between" style={{ marginBottom: 10 }}>
          <Grid.Item>
            <Dropdown>
              <Button aria-label="Switch Between Dashboards Menu">
                <EnterpriseIcon
                  icon={CaretDownIcon}
                  size="sm"
                  style={{ marginRight: 5 }}
                />
                Dashboards
              </Button>
              <Dropdown.Menu>
                {dashboardOptions?.map((option) => (
                  <Dropdown.MenuItem key={option.value}>
                    <div
                      className="default-dashboard-menu-name"
                      onClick={() => {
                        onDashboardSelected(option.value)
                      }}
                      aria-label={`Switch to ${option.label} Button`}
                    >
                      {option.label}
                    </div>
                    <div className="default-dashboard-actions">
                      <Tooltip
                        aria-label="Mark as Default Dashboard Button"
                        content="Mark as Default Dashboard"
                        location="left"
                      >
                        <span>
                          <Button
                            onClick={() => {
                              onDashboardDefault(option.value)
                            }}
                            iconOnly={true}
                            aria-label={`Set ${option.value} as Default Dashboard Button`}
                            className="default-dashboard-menu-button"
                          >
                            <EnterpriseIcon
                              size={'sm'}
                              icon={
                                defaultDashboard === option.value
                                  ? HeartFilledIcon
                                  : HeartIcon
                              }
                            />
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                  </Dropdown.MenuItem>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              <SavedSearchMenu
                mappedSavedSearchData={savedSearchData}
                onLoadSearch={onSavedSearchLoad}
                onToggleDefaultSearch={markDefault}
                onDelete={deleteSavedSearch}
              />
              <Grid.Item>
                <Button
                  onClick={() => {
                    setFiltersVisible(true)
                  }}
                  aria-label="Show Search Filters"
                  data-testid="filters-button"
                >
                  <EnterpriseIcon
                    icon={FilterIcon}
                    size="sm"
                    style={{ marginRight: 5 }}
                  />
                  Show Filters
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container direction="column">
          <Grid.Item xs={12}>
            {dashboardView === MIDDLE_MILE && (
              <MMDashboard
                loadSummary={mmLoadSummary}
                subheader={
                  <SearchFilterChips
                    searchFilters={summarySearchFilters}
                    onDelete={onSearchFilterDelete}
                    onReset={onSearchFilterReset}
                  />
                }
                formatStateFilters={formatStateFilters}
              />
            )}
            {dashboardView === FIRST_MILE && (
              <FMOutstandingLoadDashboard
                loadSummary={fmLoadSummary}
                formatStateFilters={formatStateFilters}
                subheader={
                  <SearchFilterChips
                    searchFilters={summarySearchFilters}
                    onDelete={onSearchFilterDelete}
                    onReset={onSearchFilterReset}
                  />
                }
              />
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default Dashboard
